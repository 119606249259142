import { App } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { Device } from "@capacitor/device";
import { LocalNotifications } from "@capacitor/local-notifications";
import {
  FirebaseMessaging,
  GetTokenOptions,
} from "@capacitor-firebase/messaging";
import { ref } from "vue";

import apiService from "@/core/services/api.service";
import router from "@/router";
import { useAnimalOperatorStore } from "@/stores/AnimalOperatorStore";
import { useAnimalRancherStore } from "@/stores/AnimalRancherStore";
import { useContractOperatorStore } from "@/stores/ContractOperatorStore";
import { useContractRancherStore } from "@/stores/ContractRancherStore";
import { useFeriaStore } from "@/stores/FeriaStore";
import { useRateStore } from "@/stores/RateStore";
import { useTemplateStore } from "@/stores/TemplateStore";
import { SnsPayload } from "@/types/types";

import { clearAllModals } from "./useModalBackButton";

export function useFirebaseMessaging() {
  const permissionGranted = ref<boolean>(false);
  const fcmToken = ref<string | null>(null);

  const requestPermissions = async () => {
    await FirebaseMessaging.requestPermissions();
  };

  const deleteDeviceUuid = async () => {
    const deviceUuid = (await Device.getId()).identifier;
    const formData = new FormData();
    formData.append("deviceUuid", deviceUuid);
    try {
      await apiService.deleteWithBody("/notification-service/delete/uuid", {
        deviceUuid,
      });
    } catch (error) {
      console.error("Error deleting device:", error);
    }
  };

  const disableDevice = async () => {
    const formData = new FormData();
    formData.append("deviceToken", fcmToken.value);

    try {
      await apiService.post("/notification-service/device/disable", {
        deviceToken: fcmToken.value,
      });
    } catch (error) {
      console.error("Error disabling device:", error);
    }
  };

  const reloadAllStores = () => {
    const animalOperatorStore = useAnimalOperatorStore();
    const contractOperatorStore = useContractOperatorStore();
    const animalRancherStore = useAnimalRancherStore();
    const contractRancherStore = useContractRancherStore();
    const feriaStore = useFeriaStore();
    const rateStore = useRateStore();
    const templateStore = useTemplateStore();
    animalOperatorStore.toggleLoadAnimals();
    contractOperatorStore.toggleLoadContracts();
    contractOperatorStore.toggleLoadOfflineContracts();
    animalRancherStore.toggleLoadAnimals();
    animalRancherStore.toggleLoadAddAnimalsToContract();
    contractRancherStore.toggleLoadContracts();
    feriaStore.toggleLoadAdverts();
    feriaStore.toggleLoadAdvertProposals();
    rateStore.toggleLoadRates();
    templateStore.toggleLoadTemplates();
  };

  const unsubscribeFromNotifications = async () => {
    try {
      // Remove all Firebase listeners
      await FirebaseMessaging.removeAllListeners();

      // Remove local notification listeners if on native platform
      if (Capacitor.getPlatform() !== "web") {
        await LocalNotifications.removeAllListeners();
      }

      // Clear the FCM token reference
      fcmToken.value = null;

      // Reset permission status
      permissionGranted.value = false;
    } catch (error) {
      console.error("Error unsubscribing from notifications:", error);
    }
  };

  const init = async (cleanupFirst = false) => {
    if (cleanupFirst) {
      await unsubscribeFromNotifications();
    }

    permissionGranted.value = true;

    if (Capacitor.getPlatform() !== "web") {
      await requestPermissions();
      await checkNotificationPermissions();

      // Create default notification channel
      await FirebaseMessaging.createChannel({
        id: "notification_channel",
        name: "Notification Channel",
        description: "Notification Channel",
        importance: 4, // HIGH
        visibility: 1,
        lights: true,
        vibration: true,
      });
    }

    FirebaseMessaging.removeAllListeners().then(async () => {
      getToken();
      addNotificationReceivedListener();
      addNotificationActionPerformedListener();
    });

    if (Capacitor.getPlatform() !== "web") {
      // Listen for local notification actions
      LocalNotifications.addListener(
        "localNotificationActionPerformed",
        async (event) => {
          const url = event.notification.extra.url;

          if (url && url.trim() !== "") {
            await clearAllModals();
            // Wait for preventing redirections on modal closing
            setTimeout(async () => {
              await router.push({
                path: url,
              });
            }, 200);
            reloadAllStores();
          } else {
            console.error("No valid URL found in notification data");
          }
        },
      );
    }

    if (Capacitor.getPlatform() === "web") {
      navigator.serviceWorker.addEventListener("message", (event: any) => {
        const url = event.data.data.url;

        // Set permission status to true
        permissionGranted.value = true;

        const notification = new Notification(event.data.notification.title, {
          body: event.data.notification.body,
          icon: "/img/icons/android-chrome-192x192.png",
          badge: "/img/icons/android-chrome-192x192.png",
          data: event.data.data, // Include the data object
          tag: "notification", // Add a tag to group notifications
          requireInteraction: true, // Keep notification visible until user interacts
        });

        // Set badge count
        if ("setAppBadge" in navigator) {
          navigator.setAppBadge(1).catch((error: Error) => {
            console.error("Failed to set badge:", error);
          });
        }

        // eslint-disable-next-line no-unused-vars
        notification.onclick = async (event) => {
          if (url && url.trim() !== "") {
            await clearAllModals();
            // Wait for preventing redirections on modal closing
            setTimeout(async () => {
              await router.push({
                path: url,
              });
            }, 200);
            reloadAllStores();
          } else {
            console.error("No valid URL found in notification data");
          }
        };
      });
    }
  };

  const scheduleLocalNotification = async (
    title: string,
    body: string,
    url: string,
  ) => {
    try {
      // Create notification channel group first
      await LocalNotifications.createChannel({
        id: "notifications",
        name: "Notifications",
        description: "Application notifications",
        importance: 4, // HIGH
        visibility: 1,
        lights: true,
        vibration: true,
      });

      const notificationId = Math.floor(Math.random() * 100000);

      await LocalNotifications.schedule({
        notifications: [
          {
            title,
            body,
            id: notificationId,
            schedule: { at: new Date(Date.now() + 1000) },
            attachments: null,
            actionTypeId: "",
            extra: { url },
            // Android specific options
            channelId: "notifications",
            smallIcon: "ic_notification",
            largeIcon: "ic_launcher",
            group: "notification_group",
            groupSummary: true,
            ongoing: false,
            autoCancel: true,
          },
        ],
      });
    } catch (error) {
      console.error("Failed to schedule local notification:", error);
      // Fallback: Use direct notification if local notification fails
      try {
        const notification = new Notification(title, {
          body: body,
          icon: "/img/icons/android-chrome-192x192.png",
          badge: "/img/icons/android-chrome-192x192.png",
        });

        notification.onclick = async () => {
          if (url && url.trim() !== "") {
            await clearAllModals();
            setTimeout(async () => {
              await router.push({ path: url });
            }, 200);
            reloadAllStores();
          }
        };
      } catch (fallbackError) {
        console.error("Fallback notification also failed:", fallbackError);
      }
    }
  };

  const addNotificationReceivedListener = async () => {
    FirebaseMessaging.addListener("notificationReceived", async (event) => {
      const { title, body, data } = event.notification;
      const url = data.url;

      if (Capacitor.getPlatform() !== "web") {
        // Add permission check for Android
        if (Capacitor.getPlatform() === "android") {
          const permResult = await LocalNotifications.checkPermissions();
          if (permResult.display !== "granted") {
            await LocalNotifications.requestPermissions();
          }
        }
        await scheduleLocalNotification(title, body, url);
      }
    });
  };

  // Add this new method to check and request permissions if needed
  const checkNotificationPermissions = async () => {
    if (Capacitor.getPlatform() === "android") {
      const permResult = await LocalNotifications.checkPermissions();
      if (permResult.display !== "granted") {
        return await LocalNotifications.requestPermissions();
      }
      return permResult;
    }
    return { display: "granted" };
  };

  const addNotificationActionPerformedListener = async () => {
    FirebaseMessaging.addListener(
      "notificationActionPerformed",
      async (event) => {
        const url = event.notification.data.url;
        if (url && url.trim() !== "") {
          await clearAllModals();
          // Wait for preventing redirections on modal closing
          setTimeout(async () => {
            await router.push({
              path: url,
            });
          }, 200);
          reloadAllStores();
        } else {
          console.error("No valid URL found in notification data");
        }
      },
    );
  };

  const getToken = async () => {
    const options: GetTokenOptions = {
      vapidKey: import.meta.env.VITE_APP_FIREBASE_VAPID_KEY as string,
    };

    if (Capacitor.getPlatform() === "web") {
      try {
        // Clear existing registrations
        const existingRegistrations =
          await navigator.serviceWorker.getRegistrations();
        await Promise.all(existingRegistrations.map((r) => r.unregister()));

        // Register new service worker
        const registration = await navigator.serviceWorker.register(
          "/firebase-messaging-sw.js",
          {
            scope: "/firebase-cloud-messaging-push-scope",
            updateViaCache: "none",
          },
        );

        // Wait for the service worker to be activated
        if (registration.installing) {
          await new Promise((resolve) => {
            registration.installing!.addEventListener("statechange", (e) => {
              if ((e.target as ServiceWorker).state === "activated") {
                resolve(true);
              }
            });
          });
        }

        // Set registration for FCM
        options.serviceWorkerRegistration = registration;
      } catch (error) {
        console.error("Service worker registration failed:", error);
        throw error;
      }
    }

    try {
      const { token: firebaseToken } =
        await FirebaseMessaging.getToken(options);
      fcmToken.value = firebaseToken;
      await sendTokenToServer();
    } catch (error) {
      console.error("Failed to get FCM token:", error);
      throw error;
    }
  };

  const sendTokenToServer = async () => {
    if (fcmToken.value) {
      try {
        let appVersion = "";
        if (Capacitor.getPlatform() === "android") {
          const appInfo = await App.getInfo();
          appVersion = appInfo.version;
        }
        const deviceInfo = await Device.getInfo();
        const payload: SnsPayload = {
          deviceToken: fcmToken.value,
          deviceUUID: (await Device.getId()).identifier,
          devicePlatform: Capacitor.getPlatform(),
          deviceModel: deviceInfo.model,
          deviceOsVersion: deviceInfo.osVersion,
          deviceWebViewVersion: deviceInfo.webViewVersion,
          deviceAppVersion: appVersion,
        };
        await apiService.post("/notification-service/device", payload);
      } catch (error) {
        console.error("Error sending token to server:", error);
      }
    } else {
      console.error("No token available to send to server");
    }
  };

  const MAX_REGISTRATION_RETRIES = 3;
  const BASE_RETRY_DELAY = 2000; // 2 seconds

  const refreshRegistration = async () => {
    let retryCount = 0;

    const attemptRefresh = async (): Promise<void> => {
      try {
        await init(true);
      } catch (error) {
        retryCount++;
        console.error(
          `Firebase registration attempt ${retryCount} failed:`,
          error,
        );

        if (retryCount < MAX_REGISTRATION_RETRIES) {
          const delay = BASE_RETRY_DELAY * Math.pow(2, retryCount - 1);
          await new Promise((resolve) => setTimeout(resolve, delay));
          return attemptRefresh();
        }

        throw error;
      }
    };

    return attemptRefresh();
  };

  return {
    permissionGranted,
    requestPermissions,
    deleteDeviceUuid,
    disableDevice,
    fcmToken,
    init,
    getToken,
    unsubscribeFromNotifications,
    refreshRegistration,
  };
}
