import "unfonts.css";
import "./style.css";

import { VueQueryPlugin } from "@tanstack/vue-query";
import { createPinia } from "pinia";
import piniaPluginPersistedState from "pinia-plugin-persistedstate";
import { createApp } from "vue";

import App from "./App.vue";
import { setupFirebase } from "./plugins/firebase";
import i18n from "./plugins/i18n";
import { setupPrimeVue } from "./plugins/primevue";
import { setupSentry } from "./plugins/sentry";
import { setupVeeValidate } from "./plugins/vee-validate";
import router from "./router";

const pinia = createPinia();
pinia.use(piniaPluginPersistedState);

// Setup vee-validate
setupVeeValidate();

// Setup Firebase
setupFirebase();

import { defineCustomElements } from "@ionic/pwa-elements/loader";
import { IonicVue } from "@ionic/vue";
defineCustomElements(window);

import { createHead } from "@unhead/vue";

const app = createApp(App);
const head = createHead();

app
  .use(IonicVue, {
    mode: "md",
  })
  .use(router)
  .use(pinia)
  .use(head)
  .use(i18n)
  .use(VueQueryPlugin, {
    enableDevtoolsV6Plugin: true,
  });

setupPrimeVue(app);
setupSentry(app);

router.isReady().then(() => {
  app.mount("#app");
});

// Register the service worker with auto reload
import { registerSW } from "virtual:pwa-register";
registerSW({ immediate: true });
