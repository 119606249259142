import * as Sentry from "@sentry/vue";
import type { App } from "vue";

export function setupSentry(app: App) {
  const isDevelopment = import.meta.env.MODE === "development";
  const isDemoEnv = import.meta.env.MODE === "demo";
  // const isQaEnv = import.meta.env.MODE === "qa";
  // const isPreEnv = import.meta.env.MODE === "prepro";
  const isLocal = isDevelopment || isDemoEnv;

  // Check if the environment is not local
  if (!isLocal) {
    Sentry.init({
      app,
      dsn: "https://86bb2e86d0800ceee29b278cfeed612a@o4507896044847104.ingest.de.sentry.io/4507896140726352",
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        Sentry.browserProfilingIntegration(),
      ],
      // Tracing
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        "localhost",
        /^https:\/\/qa\.gestor\.agrocontratacion\.com\//,
        /^https:\/\/demo\.gestor\.agrocontratacion\.com\//,
        /^https:\/\/pre\.gestor\.agrocontratacion\.com\//,
        /^https:\/\/gestor\.agrocontratacion\.com\//,
      ],
      // Session Replay
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      // Profiling
      profilesSampleRate: 1.0,
      environment: import.meta.env.MODE,
    });
  }
}
