import axios from "axios";

import apiService from "./api.service";
class AuthService {
  getCognitoCredentials() {
    const authStore = JSON.parse(localStorage.getItem("authStore"));
    if (authStore && authStore.cognitoCredentials) {
      return authStore.cognitoCredentials;
    }
    return null;
  }

  authHeaderCognito() {
    const cognitoCredentials = this.getCognitoCredentials();

    if (cognitoCredentials) {
      return {
        "x-idtoken": cognitoCredentials.idToken,
        "x-accesstoken": cognitoCredentials.accessToken,
      };
    }
    return {};
  }

  async cognitoAuth({ username, password }) {
    const path = "https://cognito-idp.eu-west-1.amazonaws.com";
    const formData = {
      AuthFlow: "USER_PASSWORD_AUTH",
      ClientId: import.meta.env.VITE_COGNITO_CLIENT_ID,
      AuthParameters: {
        USERNAME: username,
        PASSWORD: password,
      },
    };
    const config = {
      headers: {
        "Content-Type": "application/x-amz-json-1.1",
        "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
      },
    };

    try {
      const response = await axios.post(path, formData, config);
      const { AuthenticationResult } = response.data;
      return AuthenticationResult;
    } catch (error) {
      console.error("Error during authentication:", error);
      throw error;
    }
  }

  async cognitoRefreshToken({ refreshToken }) {
    const path = "https://cognito-idp.eu-west-1.amazonaws.com";
    const formData = {
      AuthFlow: "REFRESH_TOKEN_AUTH",
      ClientId: import.meta.env.VITE_COGNITO_CLIENT_ID,
      AuthParameters: {
        REFRESH_TOKEN: refreshToken,
      },
    };
    const config = {
      headers: {
        "Content-Type": "application/x-amz-json-1.1",
        "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
      },
    };

    try {
      const response = await axios.post(path, formData, config);
      const { AuthenticationResult } = response.data;
      return AuthenticationResult;
    } catch (error) {
      this.logError(error);
      throw error;
    }
  }

  async logoutCognito() {
    try {
      await apiService.post("/session/logout", {});
    } catch (error) {
      console.error("Error during cognito logout:", error);
    }
  }

  logErrorData = (data: any) => {
    console.log("=== ERROR DATA START ===");
    console.log("Timestamp:", new Date().toISOString());

    if (!data) {
      console.log("No error data available");
      return;
    }

    Object.entries(data).forEach(([key, value]) => {
      console.log(`${key}:`, value);
    });

    console.log("=== ERROR DATA END ===");
  };

  logError = (error: any) => {
    console.log("=== REFRESH ERROR ===");
    console.log("Error name:", error.name);
    console.log("Error message:", error.message);
    console.log("Error code:", error.code);
    console.log("Response status:", error?.response?.status);
    console.log("Response text:", error?.response?.statusText);
    console.log("Response data:", error?.response?.data);

    if (error?.response?.data) {
      this.logErrorData(error.response.data);
    }
    console.log("Request URL:", error?.config?.url);
    console.log("Request method:", error?.config?.method);
    console.log("Request headers:", error?.config?.headers);
    console.log("=== REFRESH ERROR END ===");
  };
}

export default new AuthService();
