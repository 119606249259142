import { defineStore } from "pinia";
import { ref } from "vue";

import { useFirebaseMessaging } from "@/composables/useFirebaseMessaging";

const REFRESH_INTERVAL = 1000 * 60 * 60 * 24; // 24 hours in milliseconds
const MAX_CONSECUTIVE_FAILURES = 3;

export const useFirebaseStore = defineStore("firebase", () => {
  const refreshTimerId = ref<number | null>(null);
  const consecutiveFailures = ref(0);
  const lastRefreshAttempt = ref<Date | null>(null);
  const { refreshRegistration } = useFirebaseMessaging();

  const startRefreshCron = async () => {
    if (refreshTimerId.value) return; // Prevent multiple timers

    console.log("🔄 Starting Firebase refresh cron...");

    const scheduleNextRefresh = async () => {
      try {
        console.log("📱 Attempting Firebase registration refresh...");
        await refreshRegistration();
        consecutiveFailures.value = 0;
        lastRefreshAttempt.value = new Date();
        console.log("✅ Firebase refresh completed successfully");
      } catch (error) {
        consecutiveFailures.value++;
        console.error("Firebase refresh failed:", error);

        if (consecutiveFailures.value >= MAX_CONSECUTIVE_FAILURES) {
          stopRefreshCron();
          console.error(
            "Firebase refresh cron stopped due to consecutive failures",
          );
          return;
        }
      }

      // Schedule next refresh
      refreshTimerId.value = window.setTimeout(
        scheduleNextRefresh,
        REFRESH_INTERVAL,
      );
    };

    // Start the first refresh cycle
    scheduleNextRefresh();
  };

  const stopRefreshCron = async () => {
    if (refreshTimerId.value) {
      window.clearTimeout(refreshTimerId.value);
      refreshTimerId.value = null;
    }
  };

  const forceRefresh = async () => {
    try {
      await refreshRegistration();
      consecutiveFailures.value = 0;
      lastRefreshAttempt.value = new Date();
    } catch (error) {
      console.error("Force refresh failed:", error);
      throw error;
    }
  };

  const $reset = () => {
    // Stop any ongoing refresh timer
    stopRefreshCron();

    // Reset all state to initial values
    refreshTimerId.value = null;
    consecutiveFailures.value = 0;
    lastRefreshAttempt.value = null;
  };

  // Cleanup on store disposal
  const $dispose = () => {
    stopRefreshCron();
  };

  return {
    startRefreshCron,
    stopRefreshCron,
    forceRefresh,
    lastRefreshAttempt,
    consecutiveFailures,
    $reset,
    $dispose,
  };
});
