import { Capacitor } from "@capacitor/core";
import axios from "axios";

import authService from "./core/services/auth.service";
import { useAuthStore } from "./stores/AuthStore";
import { useFirebaseStore } from "./stores/FirebaseStore";

// Platform-specific configurations
const DELAYS = {
  web: {
    BASE_DELAY: 1000,
    MAX_DELAY: 5000,
  },
  capacitor: {
    BASE_DELAY: 2000,
    MAX_DELAY: 5000,
  },
};

// Get platform-specific delay configuration
const getDelayConfig = () =>
  Capacitor.getPlatform() === "web" ? DELAYS.web : DELAYS.capacitor;

// Calculate exponential backoff with jitter
const calculateDelay = (retryCount: number): number => {
  const { BASE_DELAY, MAX_DELAY } = getDelayConfig();
  const exponentialDelay = BASE_DELAY * Math.pow(2, retryCount - 1);
  const jitter = exponentialDelay * 0.2 * Math.random(); // 20% jitter
  return Math.min(exponentialDelay + jitter, MAX_DELAY);
};

const iaxios = axios.create({
  withCredentials: false,
});
let refreshRetryCount = 0;
const MAX_REFRESH_RETRIES = 5;
const RETRY_DELAY_MS = 1000;

iaxios.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

iaxios.interceptors.response.use(
  (response) => {
    refreshRetryCount = 0;
    return response;
  },
  async (error) => {
    // Skip refresh for logout requests
    if (error.config.url?.includes("/delete/uuid")) {
      return Promise.reject(error);
    }

    if (error.code === "ECONNREFUSED") {
      handleLogout();
    } else if (
      (error.request.status === 401 ||
        error.request.status === 403 ||
        error.code === "ERR_NETWORK") &&
      refreshRetryCount < MAX_REFRESH_RETRIES
    ) {
      refreshRetryCount++;
      try {
        const delay = calculateDelay(refreshRetryCount);
        console.log(`Attempting refresh with ${delay}ms delay`);
        await new Promise((resolve) => setTimeout(resolve, delay));
        const refreshResult = await handleRefreshToken();

        // Restart Firebase refresh cron after token refresh
        const firebaseStore = useFirebaseStore();
        try {
          await firebaseStore.stopRefreshCron();
          await firebaseStore.startRefreshCron();
        } catch (firebaseError) {
          console.warn("Firebase cron restart failed:", firebaseError);
          // Continue anyway since the main token refresh was successful
        }

        // Enhanced logging for successful token refresh
        console.log("Token refresh result:", {
          success: true,
          retryCount: refreshRetryCount,
          originalRequest: {
            url: error.config?.url,
            method: error.config?.method,
            headers: error.config?.headers,
          },
          refreshedTokens: {
            hasAccessToken: !!refreshResult?.AccessToken,
            hasIdToken: !!refreshResult?.IdToken,
            expiresIn: refreshResult?.ExpiresIn,
          },
          timestamp: new Date().toISOString(),
        });

        const timestamp = new Date().toISOString();
        console.log(`[${timestamp}] ====== REFRESH RESULT ======`);
        console.log(
          `[${timestamp}] Has access token: ${!!refreshResult?.AccessToken}`,
        );
        console.log(`[${timestamp}] Has ID token: ${!!refreshResult?.IdToken}`);
        console.log(`[${timestamp}] Expires in: ${refreshResult?.ExpiresIn}`);
        console.log(`[${timestamp}] ====== TOKEN REFRESH END ======`);

        // Original request retry
        console.log(`[${timestamp}] ====== RETRYING REQUEST ======`);
        console.log(`[${timestamp}] Retry URL: ${error.config?.url}`);
        console.log(`[${timestamp}] Retry method: ${error.config?.method}`);
        console.log(`[${timestamp}] Retry count: ${refreshRetryCount}`);
        console.log(`[${timestamp}] ====== RETRY END ======`);
      } catch (refreshError) {
        // Enhanced error logging for refresh failures
        console.error("Token refresh failed:", {
          error: {
            message: refreshError.message,
            code: refreshError.code,
            name: refreshError.name,
            stack: refreshError.stack,
          },
          response: {
            status: refreshError?.response?.status,
            statusText: refreshError?.response?.statusText,
            data: refreshError?.response?.data,
          },
          request: {
            url: refreshError?.config?.url,
            method: refreshError?.config?.method,
            headers: refreshError?.config?.headers,
          },
          retryCount: refreshRetryCount,
          maxRetries: MAX_REFRESH_RETRIES,
          timestamp: new Date().toISOString(),
        });

        // Logout if max retries reached
        console.log("=== REFRESH ERROR ===");
        console.log("Error name:", refreshError.name);
        console.log("Error message:", refreshError.message);
        console.log("Error code:", refreshError.code);
        console.log("Response status:", refreshError?.response?.status);
        console.log("Response text:", refreshError?.response?.statusText);
        console.log(
          "Response data:",
          JSON.stringify(refreshError?.response?.data),
        );
        console.log("Stack trace:", refreshError.stack);
        console.log("=== REFRESH ERROR END ===");

        if (refreshRetryCount >= MAX_REFRESH_RETRIES) {
          console.error("Max refresh retries reached, logging out", {
            totalAttempts: refreshRetryCount,
            finalError: refreshError,
            timestamp: new Date().toISOString(),
          });
          handleLogout();
          return Promise.reject(error);
        }
      }

      // Retry the original request with enhanced logging
      await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY_MS));
      const originalRequest = error.config;
      originalRequest.headers = {
        ...originalRequest.headers,
        ...authService.authHeaderCognito(),
      };

      console.log("Retrying original request with new tokens:", {
        url: originalRequest.url,
        method: originalRequest.method,
        retryCount: refreshRetryCount,
        timestamp: new Date().toISOString(),
      });

      return iaxios(originalRequest);
    } else {
      return Promise.reject(error);
    }
  },
);

function handleLogout() {
  const authStore = useAuthStore();
  authStore.logoutAfterNetworkError();
  // authStore.logout();
}

async function handleRefreshToken() {
  const authStore = useAuthStore();
  return authStore.refreshToken();
}

export default iaxios;
