import { localize, setLocale } from "@vee-validate/i18n";
import es from "@vee-validate/i18n/dist/locale/es.json";
import { email, required } from "@vee-validate/rules";
import { configure, defineRule } from "vee-validate";

export function setupVeeValidate() {
  // Vee Validate rules
  defineRule("required", required);
  defineRule("email", email);

  configure({
    validateOnInput: true,
    generateMessage: localize({
      es,
    }),
  });
  // Initial locale
  setLocale("es");
}
