import { defineStore } from "pinia";
import { computed, ref } from "vue";

import { RancherTypes, UserRoles, UserStatus } from "@/constants/constants";
import apiService from "@/core/services/api.service";
import type { Device, User } from "@/types/types";

import { useTemplateStore } from "./TemplateStore";

export const useUserStore = defineStore(
  "userStore",
  () => {
    // State as refs
    const user = ref<User>({} as User);
    const lang = ref("es");
    const token = ref("");
    const hasIban = ref<boolean | undefined>(undefined);
    const preferredLayout = ref("list");
    const isLoggingOut = ref(false);
    const devices = ref<Device[]>([]);

    // Actions as functions
    const fetchUser = async () => {
      return apiService
        .get("/usuarios/actual")
        .then((response) => {
          user.value = response.data;
        })
        .catch((error) => {
          console.error("Error fetching user:", error);
        });
    };

    const checkAndFetchUser = async () => {
      if (!user.value || Object.keys(user.value).length === 0) {
        await fetchUser();
      }
    };

    const setToken = (newToken: string) => {
      token.value = newToken;
    };

    const fetchHasIban = async () => {
      try {
        const response = await apiService.get("/iban/has-valid-iban");
        hasIban.value = response.data;
      } catch (error) {
        console.error("Error fetching Iban:", error);
      }
    };

    const checkAndFetchHasIban = async () => {
      if (hasIban.value === undefined) {
        await fetchHasIban();
      }
    };

    // Convert getters to computed properties
    const isRancher = computed(() => {
      return RancherTypes.includes(user.value.role);
    });

    const isIndividualRancher = computed(() => {
      return user.value.role === UserRoles.INDIVIDUAL_RANCHER;
    });

    const isCompanyRancher = computed(() => {
      return user.value.role === UserRoles.COMPANY_RANCHER;
    });

    const isOperator = computed(() => {
      return user.value.role === UserRoles.OPERATOR;
    });

    const isRancherFixedContractAllowed = computed(() => {
      const templateStore = useTemplateStore();
      return templateStore.hasFixedPriceTemplates && isRancher.value;
    });

    const isRancherRateContractAllowed = computed(() => {
      const templateStore = useTemplateStore();
      return templateStore.hasRateTemplates && isRancher.value;
    });

    const isRancherContractAllowed = computed(() => {
      return (
        isRancherFixedContractAllowed.value ||
        isRancherRateContractAllowed.value
      );
    });

    const isRancherProvisioningContractAllowed = computed(() => {
      const templateStore = useTemplateStore();
      return templateStore.hasProvisioningTemplates && isRancher.value;
    });

    const isRancherFeriaContractAllowed = computed(() => {
      const templateStore = useTemplateStore();
      return templateStore.hasFeriaTemplates && isRancher.value;
    });

    const isRancherOfflineContractAllowed = computed(() => {
      const templateStore = useTemplateStore();
      return templateStore.hasOfflineTemplates && isRancher.value;
    });

    const isOperatorFixedContractAllowed = computed(() => {
      const templateStore = useTemplateStore();
      return templateStore.hasFixedPriceTemplates && isOperator.value;
    });

    const isOperatorRateContractAllowed = computed(() => {
      const templateStore = useTemplateStore();
      return templateStore.hasRateTemplates && isOperator.value;
    });

    const isOperatorContractAllowed = computed(() => {
      return (
        isOperatorFixedContractAllowed.value ||
        isOperatorRateContractAllowed.value
      );
    });

    const isOperatorProvisioningContractAllowed = computed(() => {
      const templateStore = useTemplateStore();
      return templateStore.hasProvisioningTemplates && isOperator.value;
    });

    const isOperatorFeriaContractAllowed = computed(() => {
      const templateStore = useTemplateStore();
      return templateStore.hasFeriaTemplates && isOperator.value;
    });

    const isOperatorOfflineContractAllowed = computed(() => {
      const templateStore = useTemplateStore();
      return templateStore.hasOfflineTemplates && isOperator.value;
    });

    const isActive = computed(() => {
      return user.value.estado === UserStatus.ACTIVE;
    });

    const isInactive = computed(() => {
      return user.value.estado === UserStatus.INACTIVE;
    });

    const isPartial = computed(() => {
      return user.value.role === UserRoles.PARTIAL;
    });

    // Reset function
    const $reset = () => {
      user.value = {} as User;
      lang.value = "es";
      token.value = "";
      hasIban.value = undefined;
      preferredLayout.value = "list";
      isLoggingOut.value = false;
      devices.value = [];
    };

    return {
      // State
      user,
      lang,
      token,
      hasIban,
      preferredLayout,
      isLoggingOut,
      devices,
      // Actions
      fetchUser,
      checkAndFetchUser,
      setToken,
      fetchHasIban,
      checkAndFetchHasIban,
      // Computed
      isRancher,
      isIndividualRancher,
      isCompanyRancher,
      isOperator,
      isRancherFixedContractAllowed,
      isRancherRateContractAllowed,
      isRancherContractAllowed,
      isRancherOfflineContractAllowed,
      isOperatorFixedContractAllowed,
      isOperatorRateContractAllowed,
      isOperatorContractAllowed,
      isOperatorOfflineContractAllowed,
      isRancherProvisioningContractAllowed,
      isRancherFeriaContractAllowed,
      isOperatorProvisioningContractAllowed,
      isOperatorFeriaContractAllowed,
      isActive,
      isInactive,
      isPartial,
      // Reset
      $reset,
    };
  },
  {
    persist: true,
  },
);
