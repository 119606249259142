import { Capacitor } from "@capacitor/core";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: import.meta.env.VITE_APP_FIREBASE_API_KEY as string,
  authDomain: import.meta.env.VITE_APP_FIREBASE_AUTH_DOMAIN as string,
  projectId: import.meta.env.VITE_APP_FIREBASE_PROJECT_ID as string,
  storageBucket: import.meta.env.VITE_APP_FIREBASE_STORAGE_BUCKET as string,
  messagingSenderId: import.meta.env
    .VITE_APP_FIREBASE_MESSAGING_SENDER_ID as string,
  appId: import.meta.env.VITE_APP_FIREBASE_APP_ID as string,
  measurementId: import.meta.env.VITE_APP_MEASUREMENT_ID as string,
  vapidKey: import.meta.env.VITE_APP_FIREBASE_VAPID_KEY as string,
};

export function setupFirebase() {
  if (!Capacitor.isNativePlatform()) {
    initializeApp(firebaseConfig);
  }
}
