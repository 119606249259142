import { Directory, Filesystem } from "@capacitor/filesystem";
import { isPlatform, useIonRouter } from "@ionic/vue";
import { defineStore, getActivePinia } from "pinia";
import { computed, ref } from "vue";

import { useFirebaseMessaging } from "@/composables/useFirebaseMessaging";
import authService from "@/core/services/auth.service";
import i18n from "@/plugins/i18n";

import { useFirebaseStore } from "./FirebaseStore";

export const useAuthStore = defineStore(
  "authStore",
  () => {
    const router = useIonRouter();
    const { deleteDeviceUuid, unsubscribeFromNotifications } =
      useFirebaseMessaging();
    const firebaseStore = useFirebaseStore();

    // State as refs
    const stateOneTimePassword = ref(null);
    const cognitoCredentials = ref({
      accessToken: null,
      refreshToken: null,
      idToken: null,
      expiresIn: null,
      tokenType: null,
    });
    const refreshResponse = ref({});

    // Computed properties
    const isLoggedIn = computed(() => {
      return cognitoCredentials.value.accessToken !== null;
    });

    // Actions as functions
    const login = async ({ username, password }) => {
      try {
        const cognitoAuthResponse = await authService.cognitoAuth({
          username,
          password,
        });

        cognitoCredentials.value = {
          accessToken: cognitoAuthResponse.AccessToken,
          refreshToken: cognitoAuthResponse.RefreshToken,
          idToken: cognitoAuthResponse.IdToken,
          expiresIn: cognitoAuthResponse.ExpiresIn,
          tokenType: cognitoAuthResponse.TokenType,
        };

        return cognitoAuthResponse;
      } catch (error) {
        console.error("Error during authentication:", error);
        throw error;
      }
    };

    const logout = async () => {
      await router.push("/es/logout");

      // Stop Firebase refresh scheduling before logout
      await firebaseStore.stopRefreshCron();
      await unsubscribeFromNotifications();
      // Current implementation logouts ALL user devices, and we dont want that
      // await authService.logoutCognito();
      await deleteDeviceUuid();

      if (isPlatform("android") || isPlatform("ios")) {
        try {
          await Filesystem.rmdir({
            path: "",
            directory: Directory.External,
            recursive: true,
          });
        } catch (error) {
          console.error("Error deleting app data directory:", error);
        }
      }

      try {
        // Force reset Pinia stores before navigation
        const pinia = getActivePinia();
        if (pinia) {
          await pinia._s.forEach((store) => {
            if (store.$reset) store.$reset();
          });
        }
      } catch (error) {
        console.error("Error resetting Pinia stores:", error);
      }

      try {
        await localStorage.clear();
      } catch (error) {
        console.error("Error cleaning local storage:", error);
      }
    };

    // same as logout but without auth api calls
    const logoutAfterNetworkError = async () => {
      await router.push("/es/logout");

      // Stop Firebase refresh scheduling before logout
      await firebaseStore.stopRefreshCron();
      await unsubscribeFromNotifications();

      if (isPlatform("android") || isPlatform("ios")) {
        try {
          await Filesystem.rmdir({
            path: "",
            directory: Directory.External,
            recursive: true,
          });
        } catch (error) {
          console.error("Error deleting app data directory:", error);
        }
      }

      try {
        // Force reset Pinia stores before navigation
        const pinia = getActivePinia();
        if (pinia) {
          await pinia._s.forEach((store) => {
            if (store.$reset) store.$reset();
          });
        }
      } catch (error) {
        console.error("Error resetting Pinia stores:", error);
      }

      try {
        await localStorage.clear();
      } catch (error) {
        console.error("Error cleaning local storage:", error);
      }
    };

    const refreshToken = async () => {
      try {
        const cognitoRefreshResponse = await authService.cognitoRefreshToken({
          refreshToken: cognitoCredentials.value.refreshToken,
        });

        cognitoCredentials.value = {
          ...cognitoCredentials.value,
          accessToken: cognitoRefreshResponse.AccessToken,
          idToken: cognitoRefreshResponse.IdToken,
          expiresIn: cognitoRefreshResponse.ExpiresIn,
          tokenType: cognitoRefreshResponse.TokenType,
          refreshToken:
            cognitoRefreshResponse.RefreshToken ||
            cognitoCredentials.value.refreshToken,
        };

        refreshResponse.value = cognitoRefreshResponse;
        return cognitoRefreshResponse;
      } catch (error) {
        console.error("Error during token refresh in authstore:", error);
        throw error;
      }
    };

    const checkOneTimePass = async () => {
      try {
        if (stateOneTimePassword.value === true) {
          router.push({
            name: "perfil-technician",
            params: { lang: i18n.global.locale, firstLogin: "true" },
          });
        } else {
          router.push({
            name: "contratos-technician",
            params: { lang: i18n.global.locale },
          });
        }
        return stateOneTimePassword.value;
      } catch (error) {
        console.error("Error checking one time password:", error);
      }
    };

    const $reset = () => {
      stateOneTimePassword.value = null;

      if (cognitoCredentials.value) {
        cognitoCredentials.value = {
          accessToken: null,
          refreshToken: null,
          idToken: null,
          expiresIn: null,
          tokenType: null,
        };
      }
      refreshResponse.value = {};
    };

    return {
      // State
      stateOneTimePassword,
      cognitoCredentials,
      refreshResponse,
      // Computed
      isLoggedIn,
      // Actions
      login,
      logout,
      logoutAfterNetworkError,
      refreshToken,
      checkOneTimePass,
      $reset,
    };
  },
  {
    persist: true,
  },
);
