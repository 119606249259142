export const AnimalStates = {
  ON_RANCH: 1,
  ON_SALE: 2,
  PROPOSED_ENTER_CONTRACT: 3,
  ON_CONTRACT: 4,
  PROPOSED_EXIT_CONTRACT: 5,
  SLAUGHTERED: 6,
  PROPOSED_SLAUGHTER: 7,
  DISMISSED: 8,
  DRAFT_ANIMAL: 9,
  ERROR: 10,
  ACCEPTED_EXIT_CONTRACT: 11,
  REJECTED_ENTER_CONTRACT: 12,
  DELIVERED: 13,
};

export const ContractStates = {
  NEW: 1,
  PROPOSED_TO_RANCHER: 2,
  SIGNED_BY_RANCHER: 3,
  SIGNED_BOTH_PARTIES: 4,
  PROPOSED_TO_FINISH_BY_RANCHER: 5,
  PROPOSED_FINISH_ACCEPTED_BY_RANCHER: 6,
  FINISHED: 7,
  PROPOSED_TO_FINISH_BY_OPERATOR: 8,
  PROPOSED_FINISH_ACCEPTED_BY_OPERATOR: 9,
  CANCELED: 10,
  REJECTED: 11,
  BLOCKED: 12,
  DRAFT_CONTRACT: 13,
  REVIEW_BY_TECHNICIAN: 14,
  ACCEPTED_BY_RANCHER: 15,
  FINISHED_CONSENSUALLY: 16,
};

export const AnimalStateGroup = {
  ALL: 1,
  ALIVE: 2,
  SLAUGHTERED: 3,
  AVAILABLE: 4,
  ON_RANCH: 5,
  ON_CONTRACT: 6,
  PROPOSED_ENTER_CONTRACT: 7,
  PROPOSED_EXIT_CONTRACT: 8,
  ON_SALE: 9,
  MY_ANIMALS: 10,
};

export const AdvertTypes = {
  ALL: null,
  OFFER: 1,
  DEMAND: 2,
};

export const AdvertCategories = {
  BOVINE: 1,
  OTHER: 2,
};

export const AdvertStates = {
  ALL: 0,
  ACTIVE: 1,
  INACTIVE: 2,
  SOLD: 3,
};

export const SaleTypes = {
  ALL_NULL: null,
  ALL0: 0,
  ALIVE: 1,
  SACRIFICE: 2,
  ALL: 3,
};

export const PriceTypes = {
  ALL: 1,
  RATE: 2,
  FIXED_PRICE: 3,
};

export const NewAdvertAnimalTypes = {
  EXISTING: 1,
  NEW: 2,
  PACK: 3,
};

export const Defaults = {
  DEFAULT_MEAT_QUALITY_ID: 4,
  DEFAULT_SALE_TYPE_ID: 3,
  DEFAULT_REGISTER_CAUSE: "Sin informar",
};

export const AdvertProposalStatus = {
  ALL: 0,
  OPEN: 1,
  ACCEPTED: 2,
  REJECTED: 3,
  CANCELED: 4,
  TEMPORARY_REJECTED: 5,
  DELETED: 6,
  EXPIRED: 7,
};

export function getAdvertProposalStateString(stateNumber: number): string {
  const stateMap = {
    [AdvertProposalStatus.ALL]: "Todas",
    [AdvertProposalStatus.OPEN]: "Activa",
    [AdvertProposalStatus.ACCEPTED]: "Aceptada",
    [AdvertProposalStatus.REJECTED]: "Rechazada",
    [AdvertProposalStatus.CANCELED]: "Cancelada",
    [AdvertProposalStatus.TEMPORARY_REJECTED]: "Rechazada",
    [AdvertProposalStatus.DELETED]: "Eliminada",
    [AdvertProposalStatus.EXPIRED]: "Caducada",
  };

  return stateMap[stateNumber] || "Desconocido";
}

export const TemplateConstants = {
  Type: {
    SACRIFICE: "sacrifice",
    ALIVE: "alive",
  },
  Status: {
    ACTIVE: "active",
    INACTIVE: "inactive",
  },
  PaymentType: {
    IBAN: "iban",
    PAGARE: "pagare",
  },
  PriceType: {
    RATE: "rate",
    FIXED: "fixed",
  },
  Context: {
    ALL: "all",
    FERIA: "feria",
    PROVISIONING: "online",
    OFFLINE: "offline",
  },
  Custom: {
    RADIO_FACTURA: "radio_factura",
  },
};

export const UserRoles = {
  ADMIN: "ADMIN",
  OPERATOR: "OPERADOR",
  INDIVIDUAL_RANCHER: "GANADERO",
  COMPANY_RANCHER: "EMPRESA_GANADERA",
  TECHNICIAN: "TECNICO",
  PARTIAL: "PARTIAL",
};

export const UserStatus = {
  ACTIVE: "active",
  INACTIVE: "inactive",
};

export const RancherTypes = [
  UserRoles.INDIVIDUAL_RANCHER,
  UserRoles.COMPANY_RANCHER,
];

export const Sex = {
  MALE: 1,
  FEMALE: 2,
  MALE_STRING: "Macho",
  FEMALE_STRING: "Hembra",
};

export const AnimalTabs = {
  MY_ANIMALS: "en-propiedad",
  SOLD_ANIMALS: "contrato-venta",
  BOUGTH_ANIMALS: "contrato-compra",
  NEW_ADVERT: "nuevo-anuncio",
  SELL_CONTRACT: "animales-contrato-venta",
  BUY_CONTRACT: "animales-contrato-compra",
  ADD_ANIMALS_TO_CONTRACT: "add-animales-contrato",
};

export const ContractTabs = {
  SELL_CONTRACTS: "venta",
  BUY_CONTRACTS: "compra",
};

export const AdvertViews = {
  MY_ADVERTS: "mis-anuncios",
  MY_PROPOSALS: "mis-propuestas",
  MY_PROPOSALS_RECEIVED: "propuestas-recibidas",
};
